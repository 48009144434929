<div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Behind the scenes of our kitchen, Our Blogs!</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Blogs</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="blog-area blog-area-two  pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4" *ngFor="let row of blogsFilterData;let i = index" style="margin-top: 6rem !important;">
                <div class="blog-item wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                    <a routerLink="/blog-details"  (click)="blogsDetailsPage(row)">
                        <img [src]="row.cover_img" alt="Blog" style="width:100%;height:240px">
                    </a>

                    <div class="blog-inner">
                        <span>{{row.short_desc_head}}</span>
                        <h3><a routerLink="/blog-details" (click)="blogsDetailsPage(row)">{{row.title}}</a></h3>
                        <ul>
                            <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                            <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog" (click)="blogsDetailsPage(row)">{{row.author_name}}</a></li>
                        </ul>
                        <p> {{row.short_desc | slice : 0 : 165}}..</p>
                        <a class="blog-link" routerLink="/blog-details" (click)="blogsDetailsPage(row)">Read More<i class="icofont-simple-right"></i></a>
                    </div>
                </div>
            </div>

            <!-- <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                    <a routerLink="/blog-details">
                        <img src="assets/img/home-one/blog/2.jpg" alt="Blog">
                    </a>

                    <div class="blog-inner">
                        <span>Business Case</span>
                        <h3>
                            <a routerLink="/blog-details">By Whom Your Business Is Being Loss?</a>
                        </h3>
                        <ul>
                            <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                            <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">John Doe</a></li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                        <a class="blog-link" routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                    <a routerLink="/blog-details">
                        <img src="assets/img/home-one/blog/3.jpg" alt="Blog">
                    </a>

                    <div class="blog-inner">
                        <span>Criminal Case</span>
                        <h3><a routerLink="/blog-details">Who Can The Victim A Sue After A Car Accident?</a></h3>
                        <ul>
                            <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                            <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">John Doe</a></li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                        <a class="blog-link" routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                    <a routerLink="/blog-details">
                        <img src="assets/img/home-one/blog/4.jpg" alt="Blog">
                    </a>

                    <div class="blog-inner">
                        <span>People Case</span>
                        <h3><a routerLink="/blog-details">Why Are People Doing Crime Day By Day?</a></h3>
                        <ul>
                            <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                            <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">John Doe</a></li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                        <a class="blog-link" routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                    <a routerLink="/blog-details">
                        <img src="assets/img/home-one/blog/5.jpg" alt="Blog">
                    </a>

                    <div class="blog-inner">
                        <span>Society Case</span>
                        <h3><a routerLink="/blog-details">Why Is Crime Increasing In Our Society Now?</a></h3>
                        <ul>
                            <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                            <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">John Doe</a></li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                        <a class="blog-link" routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                    <a routerLink="/blog-details">
                        <img src="assets/img/home-one/blog/6.jpg" alt="Blog">
                    </a>

                    <div class="blog-inner">
                        <span>Government Case</span>
                        <h3><a routerLink="/blog-details">Will The Government Prevent These Crimes?</a></h3>
                        <ul>
                            <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                            <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">John Doe</a></li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                        <a class="blog-link" routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                    <a routerLink="/blog-details">
                        <img src="assets/img/home-one/blog/7.jpg" alt="Blog">
                    </a>

                    <div class="blog-inner">
                        <span>Corrupted Case</span>
                        <h3><a routerLink="/blog-details">The Society Is Being Corrupted Because Of Crime</a></h3>
                        <ul>
                            <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                            <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">John Doe</a></li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                        <a class="blog-link" routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                    <a routerLink="/blog-details">
                        <img src="assets/img/home-one/blog/8.jpg" alt="Blog">
                    </a>

                    <div class="blog-inner">
                        <span>Justice Case</span>
                        <h3><a routerLink="/blog-details">You Will Get Justice If You Are Innocent</a></h3>
                        <ul>
                            <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                            <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">John Doe</a></li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                        <a class="blog-link" routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="blog-item wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                    <a routerLink="/blog-details">
                        <img src="assets/img/home-one/blog/9.jpg" alt="Blog">
                    </a>

                    <div class="blog-inner">
                        <span>Crime Case</span>
                        <h3><a routerLink="/blog-details">Stay Far From Crime Aware About Law</a></h3>
                        <ul>
                            <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                            <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">John Doe</a></li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                        <a class="blog-link" routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a>
                    </div>
                </div>
            </div> -->
        </div>

        <!-- <div class="case-pagination">
            <ul>
                <li><a routerLink="/blog"><i class="icofont-simple-left"></i></a></li>
                <li><a routerLink="/blog">1</a></li>
                <li><a routerLink="/blog">2</a></li>
                <li><a routerLink="/blog">3</a></li>
                <li><a routerLink="/blog"><i class="icofont-simple-right"></i></a></li>
            </ul>
        </div> -->
    </div>
</section>
