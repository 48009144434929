<div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>A gallery of flavors, textures, and colors.</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Gallery</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="team-area team-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="team-item wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                    <img src="assets/img/home-one/team/1.jpg" alt="Team">

                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Jerry Hudson</a></h3>
                        <span>Family Consultant</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                    <img src="assets/img/home-one/team/2.jpg" alt="Team">
                    
                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Juho Hudson</a></h3>
                        <span>Criminal Consultant</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                    <img src="assets/img/home-one/team/3.jpg" alt="Team">
                    
                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Sarah Se</a></h3>
                        <span>Divorce Consultant</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                    <img src="assets/img/home-one/team/4.jpg" alt="Team">
                    
                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Aikin Ward</a></h3>
                        <span>Business Consultant</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                    <img src="assets/img/home-one/team/5.jpg" alt="Team">
                    
                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Mic John</a></h3>
                        <span>Family Consultant</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                    <img src="assets/img/home-one/team/6.jpg" alt="Team">
                    
                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Jac Micheal</a></h3>
                        <span>Criminal Consultant</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                    <img src="assets/img/home-one/team/7.jpg" alt="Team">
                    
                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Tom Renson</a></h3>
                        <span>Divorce Consultant</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                    <img src="assets/img/home-one/team/8.jpg" alt="Team">
                    
                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Tomy Shak</a></h3>
                        <span>Business Consultant</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>