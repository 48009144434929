import { Component, OnInit } from '@angular/core';
import {OwlOptions, SlidesOutputData} from 'ngx-owl-carousel-o';
import * as menusData from '../../../../assets/json/menu-details.json';


// interface Blogs {
//   id: Number;
//   title: String;
//   cover_img: String;
//   short_desc: String;
//   long_desc: String;
//   date: String;
//   author_name: any;
// }
interface menu {
  category_title: String;
  category_img_url: String;
  menu_title:String;
  menu_price:Number;
  menu_img_url: String;
}

@Component({
  selector: 'app-home-three',
  templateUrl: './home-three.component.html',
  styleUrls: ['./home-three.component.scss']
})
export class HomeThreeComponent implements OnInit {

  menusData : any = menusData ;
  collectionSize = 0;
  menusFilterData: any;
  activePage:number = 1;
  pageSize = 6;
  selectedMenuData:any;

  activeSlides?: SlidesOutputData;
  getPassedData(data: SlidesOutputData) {
    this.activeSlides = data;
    console.log(this.activeSlides,'activeslides');
  }

  startDragging(event){
    console.log(event);
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 600,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      },
      500: {
        items: 2
      },
      // 760: {
      //   items: 3
      // },
      1000: {
        items: 3
      }
    },
    nav: false
  }

  constructor() { 
    
  }

  ngOnInit(): void {

    fetch('assets/json/menu-details.json').then(res => res.json())
    .then(jsonData => {
      
      let arr = [];
      jsonData.forEach((item) => {
          arr = [...arr, ...item.menus];
      });
      this.selectedMenuData = {
        category_title: 'All Categories',
        menus: arr
      }
      jsonData.unshift(this.selectedMenuData);
      this.menusData = jsonData;
      console.log(this.menusData ,'menusData');
      this.displayActivePage(1);
    });
  }
  displayActivePage(activePageNumber:number){
    this.menusFilterData = [];
    let arr = JSON.parse(JSON.stringify(this.menusData));
    let nextNumber: any;
    let currentNumber = (activePageNumber-1) * this.pageSize;
    nextNumber = (activePageNumber) * this.pageSize;
    for (let i=currentNumber;i < nextNumber;i++){
      if(this.menusFilterData.length < this.pageSize && i < arr.length){
        this.menusFilterData.push(arr[i]);
      }
    }
    this.activePage = activePageNumber;
  }
  menusDetailsPage(data){
    localStorage.setItem('menusDetails', JSON.stringify(data));
  }
  
}
