import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {catchError, delay, map} from "rxjs/operators";
import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient,) { }

  postService(data, url): any {
    const json = JSON.stringify(data);
    return this.http.post(url, json).pipe(
        map(this.extractData),
        catchError(this.handleError));
  }
  private extractData(res: Response): {} {
    const body = res;
    return body || {};
  }

  private handleError(error: Response | any): any {
    let errMsg: string;
    if (error instanceof Response) {
      // const body = error.json() || '';
      const err = error || JSON.stringify(error);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return observableThrowError(error);
  }
}
