<div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>From classic to contemporary, our menu has it all.</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Menu</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="blog-area blog-area-two mt-5 mx-3 px-5" >
    <div class="row admin-settings" >
      <div class="row settings-body w-100 mx-3">
        <div class="col-md-3">
          <div style="position: static" class="ps">
            <div class="ps-content">
              <div class="navbar-content ">
                <nav class="w-100">
                  <ul class="nav d-flex flex-column">
                    <li *ngFor="let category of menusData;let i = index" (click)="selectTabValue(category)">
                        <a href="javascript:"  [ngClass]="category.category_title === selectedMenuData?.category_title? 'active-btn' : ''" >
                          {{category.category_title}}
                        </a>
                      </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
    

        <div class="col-md-9 mt-3" id="focusBtn"  *ngIf="selectedMenuData">
            <div class="row">
              <h4 class="card-title mb-5" style="    color: #daa938;
    text-decoration: underline;"><b>{{selectedMenuData?.category_title}}</b></h4>
              <div class="col-sm-3  mt-2"  *ngFor="let menu of this.menusFilterData; let j = index">
                  <div style="    padding-top: 11px;
    overflow: scroll; -ms-overflow-style: none;" class="blog-item wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                      <!-- <a  (click)="menusDetailsPage(row)">
                          <img [src]="menu.menu_img_url" alt="Blog" style="height:120px">
                      </a> -->
                      <div class="blog-inner">
                          <div class="row">
                              <div class="col-sm-8">
                                <h6  >{{menu.menu_title}}</h6>  
                              </div>
                              <div class="col-sm-4">
                                  <span1 style=" color: #daa938; font-weight: bold;" >{{menu.menu_price}}</span1>
                              </div>
                              <div class="col-sm-12">
                                <p class="format">{{menu.menu_desc}}</p>
                              </div>
                          </div>
                      </div>
                  </div>
               </div>  
               <div  class="col-lg-12 col-md-12" *ngIf="this.menusData.length > 0">
                  <app-pagination
                        [totalRecords]="selectedMenuData.menus.length" [recordsPerPage]="this.pageSize" (onPageChange)="displayActivePage($event)">
                  </app-pagination>
               </div>
            </div>
        </div>
      </div>
    </div>
  </section>