<app-preloader></app-preloader>
<app-navbar></app-navbar>
    <router-outlet></router-outlet>
<app-footer></app-footer>
<div class="cookie-consent" *ngIf="show">
    <span>This site uses cookies to enhance user experience. see<a href="#" class="mx-1 text-decoration-none">Privacy policy</a></span>
    <div class=" mx-2 d-flex align-items-center justify-content-center g-2">
        <!-- <button class="allow-button mx-1" (click)="setCookie()">Allow cookies</button> -->
        <a class="cmn-btn mx-1" style="padding: 10px 17px" (click)="setCookie()">Allow </a>
        <a class="cmn-btn " style="background-color: #db3111 !important;padding: 10px 17px " (click)="removeAll()" >cancel</a>
        <!-- <button class="allow-button">cancel</button> -->
    </div>
</div>
<!-- <div class="modal" id="myModal" data-bs-backdrop="static" aria-hidden="true" >
    <div class="modal-dialog">
      <div class="modal-content">
  
       
        <div class="modal-header" >
          <button type="button" class="btn-close" data-bs-dismiss="modal" ><span style="height:16px;width:66px;Font :40px Mukta,Helvetica,sans-serif">×</span></button>
        </div>
  
       
        <div class="modal-body" >
            <div class="row">
                <div class="col-sm-12  text-center">
                    <img src="assets/img/Takeaway.jpg">
                    <h1 style="color: #daa938;font-weight: bold; font-size: 50px;">DISCOUNTS</h1><br><br>
                    <h3>Take Away(Pickup)</h3>
                    <p><span style=" color: #daa938;margin-right: 7px;font-size: 42px;">20%</span>Discount</p>
                    <h3>Delivary</h3>
                    <p><span  style=" color: #daa938;margin-right: 7px;font-size: 42px;">10%</span>Discount</p>
                    <h3>Order Through website</h3>
                    <p><span  style=" color: #daa938;margin-right: 7px;font-size: 42px;">15%</span>Discount</p><br>
                </div>
            </div>
        </div>
  
      </div>
    </div>
</div> -->