<div class="page-title-area page-title-area-two title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Book A Reservation!</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Reservation</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="contact-form contact-form-four pb-100">
    <!-- <div class="loaction-area">
        <div class="container">
            <div class="row location-bg">
                <div class="col-sm-6 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-pin"></i>
                        </div>
                        <h3>Location</h3>
                        <ul>
                            <li>115 London Rd, South Benfleet, Benfleet SS7 5UH, United Kingdom</li>
                        </ul>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-call"></i>
                        </div>

                        <h3>Phone</h3>

                        <ul>
                            <li><a href="tel: +44 7440020888"> +44 7440020888</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-email"></i>
                        </div>
                        <h3>Email</h3>
                        <ul>
                            <li><a href="mailto:hello@lufz.com">Vrishgroupsofrestaurant@gmail.com</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="container-fluid">


        <div class="d-flex justify-content-center" >
            <div id='quandoo-booking-widget'></div>
        </div>
        <div  [formGroup]="reservationForm" *ngIf="show">
            <div class="row contact-wrap">
                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="date" name="date" formControlName="date" 
                        class="form-control" required data-error="Please enter date"
                         placeholder="Date">
                         <label *ngIf="reservationForm.controls['date'].invalid && (reservationForm.controls['date'].dirty || reservationForm.controls['date'].touched)" class="alert">
                            <div *ngIf="reservationForm.controls['date'].errors.required" style="color:red">
                                Date is required
                            </div>
                        </label>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <select class="form-control" required formControlName="timeValue">
                            <option  [value]="val?.viewValue" *ngFor="let val of times">{{val?.viewValue}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="number" name="guest" formControlName="guest"
                         class="form-control" required data-error="Please update guest"
                          placeholder="Guests">
                          <label *ngIf="reservationForm.controls['guest'].invalid && (reservationForm.controls['guest'].dirty || reservationForm.controls['guest'].touched)" class="alert">
                            <div *ngIf="reservationForm.controls['guest'].errors.required" style="color:red">
                                Guests is required
                            </div>
                        </label>
                    </div>
                </div>

               
                <h4>Customer information</h4>
                 <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input  type="text" 
                         name="full name" formControlName="fullname"
                         class="form-control" required data-error="Please enter Full Name"
                          placeholder="FullName" >
                          <label *ngIf="reservationForm.controls['fullname'].invalid && (reservationForm.controls['fullname'].dirty || reservationForm.controls['fullname'].touched)" class="alert">
                            <div *ngIf="reservationForm.controls['fullname'].errors.required" style="color:red">
                                Full name is required
                            </div>
                        </label>
                    </div>
                </div>
                 <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="email" name="email" formControlName="email" 
                        class="form-control" required data-error="Please enter your Email"
                        placeholder="Email">
                        <label *ngIf="reservationForm.controls['email'].invalid && (reservationForm.controls['email'].dirty || reservationForm.controls['email'].touched)" class="alert">
                            <div *ngIf="reservationForm.controls['email'].errors.required" style="color:red">
                                Email is required
                            </div>
                        </label>
                </div>
                </div>
                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="mobile" name="phonenumber" formControlName="phonenumber"
                         class="form-control" required data-error="Please enter your Phone number"
                          placeholder="PhoneNumber">
                          <label *ngIf="reservationForm.controls['phonenumber'].invalid && (reservationForm.controls['phonenumber'].dirty || reservationForm.controls['phonenumber'].touched)" class="alert">
                            <div *ngIf="reservationForm.controls['phonenumber'].errors.required" style="color:red">
                                Phone number is required
                            </div>
                        </label>
                </div>
                </div> 
                 <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="address" formControlName="address" 
                        class="form-control" required data-error="Please enter your Address"
                         placeholder="Address">
                         <label *ngIf="reservationForm.controls['address'].invalid && (reservationForm.controls['address'].dirty || reservationForm.controls['address'].touched)" class="alert">
                            <div *ngIf="reservationForm.controls['address'].errors.required" style="color:red">
                                Address is required
                            </div>
                        </label>
                </div>
                </div> 

                <div class="col-md-12 col-lg-12">
                    <div class="text-center">
                        <button type="submit" class="contact-btn" (click)="reservationButton()">Submit</button>
                    </div>
                </div>
            </div>
        </div>


    </div> 
    <!-- <div class="container-fluid mt-5">

        <h1 style="text-align: center;">Coming Soon !</h1>
       


    </div> -->
    
</div>


