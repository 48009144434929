import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  show:boolean = true;
  mobileMenu = false;
  constructor() {

  }

  ngOnInit(): void {
  }


  getDevice(){
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // true for mobile device
      console.log("mobile device");
      this.mobileMenu = true;
    }else{
      // false for not mobile device
      this.mobileMenu = false;

      console.log("not mobile device");
    }

    return this.mobileMenu;
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event) {
   this.show = false;
  }

}
