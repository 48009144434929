<div class="page-title-area page-title-area-three title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>A gallery of flavors, textures, and colors.</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Gallery</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="container   pb-100" style="    text-align: -webkit-center;">
<!--<p>gallery works!</p>-->

<!--<button (click)="addImage()">Add image</button>-->
<div>
    <div class="mt-5 mb-4" >
        <img style="height: 600px;" class="img-responsive" src="{{ selectedImg }}" />
    </div>
    <lightgallery [settings]="settings" [onInit]="onInit">

        <a (click)="selectedImg=item.src" *ngFor="let item of items"  className="gallery-item" [attr.href]="item.src">
            <img style="width: 360px;
    height: 240px;
"  className="img-responsive" src="{{ item.thumb }}" />
        </a>
    </lightgallery>
</div>

    <div class="mt-5 mb-4">
    </div>

</div>
