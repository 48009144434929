<div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Testimonial</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Testimonial</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="testimonial-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="testimonial-item">
                    <div class="testimonial-wrap">
                        <p style="height: 300px;">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugiat, alias perferendis voluptatibus vitae id distinctio hic debitis nam, consequuntur reprehenderit rem est vero ut? Architecto reprehenderit non ducimus quidem corporis!</p>
                        <img src="assets/img/testimonial/1.jpg" alt="Testimonial">

                        <div class="testimonial-right">
                            <h3>John Lock</h3>
                            <span>Criminal Attorney</span>
                        </div>
                    </div>

                    <div class="testimonial-wrap">
                        <p style="height: 300px;">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugiat, alias perferendis voluptatibus vitae id distinctio hic debitis nam.</p>
                        <img src="assets/img/testimonial/2.jpg" alt="Testimonial">

                        <div class="testimonial-right">
                            <h3>Thomson Jack</h3>
                            <span>Children Attorney</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="testimonial-item">
                    <div class="testimonial-wrap">
                        <p style="height: 300px;">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugiat, alias perferendis voluptatibus vitae id distinctio hic debitis nam, consequuntur reprehenderit rem est vero ut? Architecto.</p>
                        <img src="assets/img/testimonial/3.jpg" alt="Testimonial">

                        <div class="testimonial-right">
                            <h3>Tom Roy</h3>
                            <span>Rights Attorney</span>
                        </div>
                    </div>

                    <div class="testimonial-wrap">
                        <p style="height: 300px;">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugiat, alias perferendis voluptatibus vitae id distinctio hic debitis nam, consequuntur.</p>
                        <img src="assets/img/testimonial/4.jpg" alt="Testimonial">

                        <div class="testimonial-right">
                            <h3>Adam Smith</h3>
                            <span>Cyber Attorney</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="testimonial-item">
                    <div class="testimonial-wrap">
                        <p style="height: 300px;">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugiat, alias perferendis voluptatibus vitae id distinctio hic debitis nam, consequuntur reprehenderit rem est vero ut? Architecto reprehenderit.</p>
                        <img src="assets/img/testimonial/5.jpg" alt="Testimonial">
                        
                        <div class="testimonial-right">
                            <h3>Bret Lee</h3>
                            <span>Political Attorney</span>
                        </div>
                    </div>

                    <div class="testimonial-wrap">
                        <p style="height: 300px;">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugiat, alias perferendis voluptatibus vitae id distinctio hic debitis nam, consequuntur reprehenderit rem est vero ut? Architecto reprehenderit non ducimus quidem corporis!</p>
                        <img src="assets/img/testimonial/6.jpg" alt="Testimonial">

                        <div class="testimonial-right">
                            <h3>Kane Williamson</h3>
                            <span>Social Attorney</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="testimonial-more">
            <a class="cmn-btn" routerLink="/testimonial">See More</a>
        </div>
    </div>
</div>