import { Component, OnInit } from '@angular/core';
import * as blogsData from '../../../../assets/json/blogs-details.json';

interface Blogs {
  id: Number;
  title: String;
  cover_img: String;
  short_desc: String;
  long_desc: String;
  date: String;
  author_name: any;
}

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  

  blogsData: Blogs[] = blogsData;
  blogsFilterData: Blogs[];
  collectionSize = 0;
  activePage:number = 1;
  pageSize = 6;

  constructor() { }

  ngOnInit(): void {

    fetch('assets/json/blogs-details.json').then(res => res.json())
    .then(jsonData => {
      this.blogsData = jsonData;
      console.log(this.blogsData ,'blogsData');
      this.collectionSize = this.blogsData.length;
      this.displayActivePage(1);
    });
  }

  displayActivePage(activePageNumber:number){
    this.blogsFilterData = [];
    let arr = JSON.parse(JSON.stringify(this.blogsData));
    let nextNumber: any;
    let currentNumber = (activePageNumber-1) * this.pageSize;
    nextNumber = (activePageNumber) * this.pageSize;
    for (let i=currentNumber;i < nextNumber;i++){
      if(this.blogsFilterData.length < this.pageSize && i < arr.length){
        this.blogsFilterData.push(arr[i]);
      }
    }
    this.activePage = activePageNumber;
  }

  blogsDetailsPage(data){
    localStorage.setItem('blogsDetails', JSON.stringify(data));
  }

}
