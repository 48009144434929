<div class="page-title-area page-title-area-two title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Order </h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>order</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="contact-form contact-form-four pb-100">
    <!-- <div class="loaction-area">
        <div class="container">
            <div class="row location-bg">
                <div class="col-sm-6 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-pin"></i>
                        </div>
                        <h3>Location</h3>
                        <ul>
                            <li>115 London Rd, South Benfleet, Benfleet SS7 5UH, United Kingdom</li>
                        </ul>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-call"></i>
                        </div>

                        <h3>Phone</h3>

                        <ul>
                            <li><a href="tel:+44 7440020888"> +44 7440020888</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-email"></i>
                        </div>
                        <h3>Email</h3>
                        <ul>
                            <li><a href="mailto:hello@lufz.com">Vrishgroupsofrestaurant@gmail.com</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="container-fluid mt-5">

        <h1 style="text-align: center;">Coming Soon !</h1>
       


    </div>
</div>
